.App {
  text-align: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f3f3f3;
}

.d-flex{
  display: flex;
}

.j-content-between{
  justify-content: space-between;
}

.j-content-evenly{
  justify-content: space-evenly;
}

.header{
  position: relative;
  width: 100%;
  top: 0;
  padding: 5px 0px 5px 15px;
  text-align: left;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  color: white;
  font-weight: 600;
  height: 50px;
  z-index: 9998;
}

.footer{
  text-align: left;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  color: white;
  font-weight: 600;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.login-background{
  width: 100%;
  height: 100%;
  display: grid;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  align-content: space-evenly;
  justify-content: center;
  align-items: center;
}

.login-logo{
  display: block;
  background-color: #e6e6e6;
  width: 45%;
  height: 150px;
  margin: auto;
  border-radius: 35px;
  text-align: center;
  line-height: 150px;
}

.login-form{
  width: 80%;
  margin: auto;
}

.btn-login{
  color: white;
  background-color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  margin: auto;
  margin-top:10px;
}

.btn-ubah-password{
  color: white;
  background-color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  margin: auto;
  margin-top:50px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-icon-search{
  background: url('../src/Assets/Icons/search-b.png') no-repeat scroll 5px 5px;
  padding-left:35px !important;
  /* margin: auto; */
}

.input-right-icon-pic{
  background: url('../src/Assets/Icons/search-b.png') no-repeat scroll 5px 5px;
  background-position: right;
  /* margin: auto; */
}

.form-tambah-2{
  display: grid;
  margin-top: 15px;
  /* margin-bottom: 15px  */
}

.form-tambah{
  display: grid;
  /* margin-top:1px;
  margin-bottom:1px; */
}

.wrapper-form {
  position:relative;
}

.icon-form {
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  padding: 2px;
  position: absolute;
  box-sizing:border-box;
  top:50%;
  /* left:0px; */
  right: 15px;
  transform: translateY(-50%);
}

.input-form-icon {
  height: 50px;
  box-sizing:border-box;
  padding-left: 1.5rem;
}

.form-input{
  display: grid;
  margin-top:10px;
  margin-bottom:10px;
}

.form-input-ubah{
  margin: auto;
  width: 95%;
  border: none;
  border-radius: 30px;
  padding: 8px 0px 8px 8px;
  margin-top : 5px;
  margin-bottom : 5px;
}

.form-input-icon{
  margin: auto;
  width: 100%;
  border: none;
  border-radius: 8px;
  margin-top : 5px;
  margin-bottom : 5px;
  padding: 10px 10px 10px;
  background-color: white;
}

.form-input-login{
  margin: auto;
  width: 95%;
  border: none;
  border-radius: 8px;
  padding: 8px 0px 8px 8px;
  margin-top : 5px;
  margin-bottom : 5px;
}

.form-input-qty{
  margin: auto;
  width: 35%;
  background-color: #dbdbdb;
  border:none;
  border-radius: 8px;
  padding: 8px 0px 8px 0px;
  margin-top : 5px;
  margin-bottom : 5px;
  text-align: center;
}

.form-input-1{
  margin: auto;
  width: 95%;
  border: 1px solid #5e5e5e;
  border-radius: 8px;
  padding: 8px 0px 8px 8px;
  margin-top : 5px;
  margin-bottom : 5px;
}

.form-input-2{
  margin: auto;
  width: 95%;
  border: 1px solid #5e5e5e;
  border-radius: 8px;
  padding: 10px 0px 10px 8px;
  margin-top : 5px;
  margin-bottom : 5px;
}

.form-input-3{
  margin: auto;
  width: 85%;
  border: none;
  border-radius: 8px;
  padding: 15px 0px 15px 8px;
  margin-top : 5px;
  margin-bottom : 5px;
}

.form-textarea-1{
  margin: auto;
  width: 85%;
  height:150px;
  border: none;
  border-radius: 8px;
  padding: 55px 0px 50px 10px;
  margin-top : 5px;
  margin-bottom : 5px;
}

.form-textarea-2{
  margin: auto;
  width: 85%;
  height:150px;
  border: 1px solid gray;
  border-radius: 8px;
  padding: 15px 0px 15px 8px;
  margin-top : 5px;
  margin-bottom : 5px;
}

.form-select-1{
  border: 0.1px solid white;
  outline: none;
  scroll-behavior: smooth;
  padding: 10px;
  width: 100%;
  background-color: transparent;
  color: black;
}

.form-select-2{
  margin: auto;
  width: 85%;
  border: none;
  border-radius: 8px;
  /* padding: 15px 0px 15px 8px; */
  margin-top : 5px;
  margin-bottom : 5px;
  text-align: left;
}

.form-select-3{
  margin: auto;
  width: 90%;
  border: none;
  border-radius: 8px;
  padding: 15px 0px 15px 8px;
  margin-top : 5px;
  margin-bottom : 5px;
  text-align: left;
}

.css-13cymwt-control{
  border-color: hsl(0deg 0% 100%) !important;
  padding: 5px;
}

.button-input-date{
  background-color: black;
  padding: 10px;
  color: white;
  border: none;
  border-radius: 10px;
  width: 85%;
  margin: auto;
  font-weight: bold;
}

.ubah-p-logo{
  display: block;
  background-color: #d1d1d1;
  width: 28%;
  height: 100px;
  line-height: 100px;
  margin: auto;
  margin-top: 15px;
  border-radius: 28px;
  text-align: center;
}

.ubah-p-form{
  width: 80%;
  margin: auto;
  margin-top:15px;
}
/* #E60D1F, #b91c1c, #930009, #930009, #930009 */
.html5-qrcode-element{
  margin-top: 9px;
  color:white;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border-radius: 25px;
  border:1px solid transparent;
  padding: 10px;
  width: 80%;
  font-weight: 600;
}

.btn-red{
  margin-top: 9px;
  color:white;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border-radius: 25px;
  border:1px solid transparent;
  padding: 10px;
  width: 100%;
  font-weight: 600;
}

.btn-red-1{
  margin-top: 9px;
  color:white;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border-radius: 25px;
  border:1px solid transparent;
  padding: 0px 10px 0px 15px;
  width: 100%;
  font-weight: 600;
}

.btn-white-1{
  margin-top: 9px;
  color:#CA0178;
  background: white;
  border-radius: 25px;
  border:3px solid #CA0178;
  padding: 0px 10px 0px 15px;
  width: 100%;
  font-weight: 600;
}

.btn-group-modal-footer{
  display: flex;
  /* align-content: center; */
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 15px;
}

.btn-modal-footer{
  margin-top: 9px;
  color:white;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border-radius: 10px;
  border:1px solid transparent;
  padding: 10px;
  width: 85px;
  height: 60px;
  font-weight: 600;
  font-size: 21px;
}

.main-page{
  /* background-color: #e6e6e6; */
  width: 100%;
  height: 90%;
  /* display: grid; */
}

.page-background{
  display: block;
  /* overflow: auto;
  height: 94vh; */
  padding: 0px 0px 35px 0px;
}

.kepegawaian-background{
  display: block;
  overflow: auto;
  height: 100vh;
  /* padding: 50px 0px 0px 0px; */
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  /* border-bottom: 1px solid #aaa; */
  margin-top:-7px;
  /* margin-bottom:10px; */
  padding: 0;
  display: flex;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -20px;
  position: relative;
  list-style: none;
  /* padding: 0px 35px; */
  cursor: pointer;
  color: #b9b9b9;
  text-align: center;
  width: 100%;
}

.react-tabs__tab--selected {
  background: transparent;
  border-color: transparent;
  color: white;
}

.react-tabs__tab--disabled {
  color: #fff;
  cursor: default;
}

.react-tabs__text{
  margin-top:5px;
  font-size: 14px;
  font-weight: 400;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.main-footer{
  margin-top:70px;
  /* overflow-y: hidden; */
}

.blur-content{
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.ps-sidebar-root{
  z-index: 9999 !important;
}

.sidebar-text{
  font-weight: 600;
  margin-top: 2px;
  margin-bottom: 7px;
}

.ps-menu-button{
  width:"90%";
  /* background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178); */
  color:"white";
  margin:"auto";
  border-radius:5;
  text-align:'left';
  padding : 10px 5px;
}

.content-cards{
  padding : 5px 5px;
  overflow-y: auto;
  height:50vh;
}

.card-hrdetail{
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 90%;
  /* height: 56vh; */
  overflow-y: auto;
  margin:auto;
  margin-top:5px;
  margin-bottom: 10px;
}

.cards-active{
  margin-top: 5px;
  background-color: #efc6c6;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.cards{
  margin-top:5px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.card-img-product{
  border-radius: 25px !important;
  padding: 8px !important;
}

.card-img{
  border-radius: 12px !important;
  padding: 8px !important;
  width: 35% !important;
}

.card-img-riwayat{
  padding:10px
}

.card-icons-2{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  padding:5px 10px 0px 20px;
}

.card-icons{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  padding:5px 10px 0px 20px;
}

.card-text{
  padding:10px 0px 0px 0px;
  width:100%
}

.card-title{
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}

.card-desc{
  margin-top:5px;
  font-size: 15px;
  color: grey;
  text-align: left;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-item{
  display: flex;
}

.card-item p{
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 11px;
}

.card-icons-beranda{
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  display: grid;
}

.outlet-card{
  margin: auto;
  margin-top:10px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 90%;
  height: 200px;
  white-space: break-spaces;
  padding: 10px 10px 50px 0px;
  background-image: url('../src/Assets/Images/kartu-nama.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.outlet-img-profile{
  width: 70%;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border-radius: 15px;
  padding: 7px 0px 7px 0px;
  margin-left: 10px;
  margin-right: 10px;
}

.outlet-menus{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
}

.outlet-button-menus{
  /* display: grid;
  justify-content: center;
  align-content: center; */
  margin: 10px;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  width: 25%;
  height: 20vh;
  padding: 10% 5px 50px 5px;
  border-radius: 25px;
  box-shadow: 1px 1px 5px black;
  font-size: medium;
}

.outlet-button-menus p{
  font-size: small;
  color: white;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 600;
}

.modal-gambar .modal-content{
  background-color: transparent !important;
  border: none;
}

.riwayat-button-menus{
  display: grid;
  justify-content: center;
  align-content: center;
  margin: 10px;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  width: 40%;
  padding: 8%;
  border-radius: 25px;
  box-shadow: 1px 1px 5px black;
}

.riwayat-button-menus p{
  font-size: medium;
  font-weight: 600;
  color: white;
  margin-top: 10px;
  margin-bottom: 0px;
}

.btn-red{
  margin:10px auto 10px auto;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  width: auto;
  border-radius: 25px;
  border: none;
  color: white;
  box-shadow: 1px 1px 5px black;
  padding: 10px 10px 10px 15px;
  font-size: 20px;
  font-weight: 700;
}

.btn-logout-white{
  margin:10px auto 10px auto;
  background-color: white;
  width: 30%;
  border-radius: 25px;
  border: 1px solid black;
  color: black;
  box-shadow: 1px 1px 5px black;
  padding: 10px 10px 10px 15px;
  font-size: 20px;
  font-weight: 700;
}

.btn-logout-red{
  margin:10px auto 10px auto;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  width: 30%;
  border-radius: 25px;
  border: none;
  color: white;
  box-shadow: 1px 1px 5px black;
  padding: 10px 10px 10px 15px;
  font-size: 20px;
  font-weight: 700;
}

.btn-scan-serial{
  margin:10px auto 10px auto;
  width: 90%;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border-radius: 25px;
  border: none;
  color: white;
  box-shadow: 1px 1px 5px black;
  font-size: 20px;
  font-weight: 700;
}

.outlet-checkout-button{
  margin:10px auto 10px auto;
  width: 90%;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border-radius: 25px;
  border: none;
  color: white;
  box-shadow: 1px 1px 5px black;
  padding: 10px 10px 10px 15px;
  font-size: 20px;
  font-weight: 700;
}

.SO-page{
  padding:2px 10px 2px 10px;
  margin-top: 10px;
}

.detail-page{
  padding:2px 1px 2px 1px;
  margin-top: 10px;
}

.SO-lists{
  background-color: white;
  text-align: left;
  padding: 5px;
  overflow: auto;
}

.stock-lists{
  background-color: white;
  text-align: left;
  padding: 5px;
  /* width: 100vw; */
  height: 75vh;
  overflow-y: auto;
}

.form-tambah-outlet{
  text-align: left;
  padding: 5px;
}

.stock-details{
  margin-top:2px;
  margin-bottom: 15px;
}

.stock-details h3{
  margin-top : 2px;
  margin-bottom : 2px;
}

.stock-details p{
  margin-top : 2px;
  margin-bottom : 10px;
}

.stock-body{
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  /* display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row; */
}

.stock-item{

}

.SO-qty-minus-button{
  width: 25%;
  height: 5vh;
  background-color: #ecc4c4;
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: white;
  font-size: 25px;
}

.SO-qty-plus-button{
  width: 25%;
  height: 5vh;
  background-color: #EC008c;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: white;
  font-size: 25px;
}

.SO-qty-input{
  width: 15%;
  height: 5vh;
  font-size: 16px;
  text-align: center;
  border: 1px solid grey;
}

.stock-qty-minus-button{
  width: 10vw;
  height: 5vh;
  background-color: #ecc4c4;
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: white;
  /* font-size: 25px; */
}

.stock-qty-plus-button{
  width: 10vw;
  height: 5vh;
  background-color: #EC008c;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: white;
  font-size: 25px;
}

.stock-qty-input{
  width: 10vw;
  height: 5vh;
  font-size: 16px;
  text-align: center;
  border: 1px solid grey;
}

.stock-qty{
  display: inline-flex;
  justify-content: center;
}

.stock-footer{
  margin-top: 15px;
  display: grid;
  width: 50%;
  text-align: center;
}

.stock-button-tanggal{
  background-color: #ff81e3;
  color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
}

.SO-body{
  display: flex;
  flex-direction: column;
}

.SO-item {
  margin: auto;
  width: 80vw;
  height: 60px;
}

.SO-item p {
  text-align: left;
  margin-right: auto;
  width: 50%;
}

.SO-qty{
  display: inline-flex;
  justify-content: flex-end;
}

.list-images{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
}

.item-image{
  border: 2px solid #EC008c;
  border-radius: 10px;
  object-fit: contain;
  background-color: white;
  width: 40vw;
  margin-top: 5px;
  margin-bottom: 5px;
}

.item-docs{
  display: grid;
  justify-content: center;
  position: relative;
}

.delete-item-pcp{
  position: absolute;
  justify-self:right;
}

.items-pcp{
  position: absolute;
  justify-self:right;
}

.add-image{
  display: grid;
  justify-content: center;
}

.add-image-button{
  border: 2px solid #EC008c;
  border-radius: 10px;
  object-fit: contain;
  background-color: white;
  width: 60vw;
}

.kepegawaian-menus{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
}

.kepegawaian-button-menus{
  display: grid;
  justify-content: center;
  align-content: center;
  margin: 10px;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  width: 40%;
  height: 22vh;
  padding: 8%;
  border-radius: 25px;
  box-shadow: 1px 1px 5px black;
  color: white;
  font-weight: 600;
}

.kepegawaian-footer{
  position: fixed;
  bottom: 0;
  /* background-image: url('../src/Assets/Images/city.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain; */
}

.modal-loader{
  display: grid;
  padding: 5px;
  justify-content: center;
  align-content: center;
}

.react-date-picker__wrapper{
  display: none !important;
}

.tick-count{
  position: absolute;
  display: grid;
  width: 78%;
  justify-content: center;
  top: 8px;
  color: #EC008c;
}

.react-date-picker{
  z-index: 9999;
}

.focus-position{
  position: absolute;
  z-index: 9;
  right: 0;
  padding: 10px;
}

.btn-focus{
  background-color: white;
  border: none;
  opacity: 0.6;
  box-shadow: 1px 1px 5px black;
}

.btn-tambah-outlet{
  color:white;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border-radius: 6px;
  border:1px solid transparent;
  padding: 8px;
  width: 95px;
  font-weight: 600;
}

.tambah-outlet-position{
  position: absolute;
  z-index: 9;
  left: 0;
  margin-top: 70px;
  padding: 10px;
  display: grid;
}

.fade-button-group{
  margin-bottom: 30px;
  margin-left: 25px;
}

.fade-button{
  margin-top: 7px;
  display: flex;
}

.fade-button p{
  background-color: white;
  box-shadow: 2px 1px 5px black;
  border-radius: 5px;
  font-size: 13px;
  color: #8f0101;
  padding: 0px 7px 0px 7px;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin: auto;
  margin-left: 15px;
}

.round-button{
  border-radius: 25px;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  border: none;
  width: 45px;
  height: 45px;
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.input-icon-strap{
  border: none !important;
  background-color: white !important;
}

.wrap-input{
  height:40px;
}

.wrap-input-image{
  height:160px;
}

.wrap-scroll{
  height: 87vh;
  overflow: auto;
}

.scroll-content{
  overflow-y: auto;
  padding :0px 5px 0px 5px;
}

.group-camera-buttons{
  position:absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  padding: 10px;
}

.group-camera-buttons p{
  margin-top: auto;
  margin-bottom: auto;
}

.camera-button{
  width: 30%;
  margin: auto;
}

.btn-home-dashboard{
  background: #7f1d7a;
  border: none;
  padding: 3px 5px 5px 5px;
  position: absolute;
  z-index: 99999990;
  bottom: 0;
  left: 0;
}

.Toastify__toast-container {
  z-index: 200000;
  }

.SplashScreen{
  
}

.radio-form{
  display: inline-flex;
  /* align-content: center; */
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-left: 25px;
  padding-right: 25px;
}

.button-upload {
  cursor: pointer;
  font: inherit;
  min-height: 48px;
  min-width: 48px;
  background: linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178);
  color: white;
  border: none;
  border-radius: 10px;
}

.item-lampiran{
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 10px;
}

.footer-register{
  width: 100%;
  height: 60px;
  display: flex;
  /* display: grid; */
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.btn-back{
  background-color: white;
  border: none;
  border-radius: 15px;
  color:#EC008c;
  height: 50px;
}

.btn-next{
  color: white;
  background-color: #EC008c;
  border: none;
  border-radius: 10px;
  height: 50px;
}

#html5qr-code-full-region__dashboard_section_csr{
  margin-bottom: 15px;
}